import React, { Component } from 'react'
import Head from 'next/head'
import { Header, Footer, Headlines, NextFixtures, LatestResults, InProgress, PageWrapper } from '../components'
import { fetchPosts, fetchImportantPosts, fetchTest } from '../api/news'
import { fetchUpcomingFixtures, fetchRecentResults, fetchInProgress } from '../api/fixtures'
import { fetchDivisions } from '../api/divisions'

class HomePage extends Component {
  static async getInitialProps({req, query, props, res}) {
    const importantPost = await fetchImportantPosts(props.organisationId, 1)
    const excludeIds = importantPost.data.map(function(p) {
      return p.id
    })
    const fixtureOrganisationIds = [props.organisationId].concat(props.organisation.child_ids)

    const fetchCount = importantPost.data.length > 0 ? 4 : 5
    const posts = await fetchPosts(props.organisationId, fetchCount, excludeIds)
    const fixturesPromise = fetchUpcomingFixtures(fixtureOrganisationIds)
    const resultsPromise = fetchRecentResults(fixtureOrganisationIds)
    const inProgressPromise = fetchInProgress(fixtureOrganisationIds)
    const allDivisionsPromise = fetchDivisions(fixtureOrganisationIds)
    return {
      posts: importantPost.data.concat(posts.data),
      fixtures: await fixturesPromise,
      results: await resultsPromise,
      inProgress: await inProgressPromise,
      allDivisions: await allDivisionsPromise,
      organisationId: props.organisationId
    }
  }

  render() {
    const { posts, fixtures, results, inProgress, allDivisions, organisationId, organisation, pages } = this.props
    let imageSrc="https://brha.co/static/photo.png"

    if (posts.length > 0) {
      const imageMatch = posts[0].html.match(/<img[^>]+src="([^">]+)"/)
      if (imageMatch) {
        imageSrc = imageMatch[1] ;
      }
    }
    return (
      <div className={`u-grid u-grid--home u-grid-${organisationId}`}>
        <Head>
          <title>{`${organisation.name} | Home`}</title>
          <meta property="og:title" content={`BRHA | Home`} />
          <meta property="og:description" content="Stats, fixtures, results and latest news from BRHA" />
          <meta property="og:image" content={imageSrc} />

        </Head>
        <Header region={organisation.name} regionId={organisation.id} pages={pages}/>

        <div className="u-grid__main">
          <Headlines headlines={posts} />
        </div>
        <div className="u-grid__fixtures" >
          <div className="c-header__trace"><a className="c-button c-button--primary c-button--block" href="https://app.form.com/f/41586188/7c39/">Request a DBS Check →</a></div>
          {inProgress.length > 0 &&
            <InProgress inProgress={inProgress} fixtures={fixtures} divisions={allDivisions} organisationId={organisationId}/>
          }
          <NextFixtures fixtures={fixtures} divisions={allDivisions} organisationId={organisationId} />
        </div>


        {results.length > 0 &&
          <LatestResults className="u-grid__results" results={results} divisions={allDivisions} organisationId={organisationId}/>
        }
        <Footer regionId={organisation.id}/>
      </div>
    )
  }

}
export default PageWrapper(HomePage)
