import { api } from './api';

export async function fetchPosts(organisationId, limit = 0, excludeIds=[]) {
    const apiInst = await api();

    const response = await apiInst.get('posts?status=published&organisation_id=' + organisationId + '&exclude_ids=' + excludeIds);
    if (limit) {
      response.data.data = response.data.data.slice(0, limit)
    }
    return response.data;
}

export async function fetchImportantPosts(organisationId, limit = 0) {
    const apiInst = await api();
    const response = await apiInst.get('posts?status=published&is_important=1&organisation_id=' + organisationId);
    if (limit) {
      response.data.data = response.data.data.slice(0, limit)
    }
    return response.data;
}

export async function fetchPost(id) {
    const apiInst = await api();
    const response = await apiInst.get('posts/' + id);
    return response.data;
}
